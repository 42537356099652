import React, { Component,lazy, Suspense } from 'react';
import './App.css';
import firebase from 'firebase';
import logo from '../../Logo.png';
import { BrowserRouter, Switch , Route} from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';




const Welcome = lazy(() => import("../Welcome"));
const Admin = lazy(() => import("../Admin"));
const Event = lazy(() => import("../Client/Event"));
const Reg = lazy(() => import("../Client/Reg"));
const SubmitFeedback = lazy(() => import("../Client/SubmitFeedback"));

const override = css`
    display: block;
    margin: 10 auto;
    text-align: center;
    border-color: #ef5002;
    vertical-align: middle;
    horizontal-align: middle;
`;






class App extends Component {
  constructor(props){
    super(props);
     var config = {
      apiKey: "AIzaSyD1CauEzoqn_4VmNT5BEZuh6vfXjXdu2zg",
      authDomain: "fulfulmentagent.firebaseapp.com",
      databaseURL: "https://fulfulmentagent.firebaseio.com",
      projectId: "fulfulmentagent",
      storageBucket: "fulfulmentagent.appspot.com",
      messagingSenderId: "275772159637"
    };
    firebase.initializeApp(config);
    this.state = {
        email: '',
        password: '',
        in: 0,
        loading: false,
        term: '',
        open: false,
        check: false,
        isShown: false,
        isActive: true,
    };
  }

  
  // When Components have been rendered
  componentDidMount() {
    this.setState({
      loading: false
    });
  }




  
  

  // Getting the email the user entered
  handleChange = name => e => {
        this.setState({
          [name] : e.target.value
        });
    }


  

   

 

  
 

  render() {
    const { isActive } = this.state
    

    let loadingView = <div className="App">
      <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>;


    let mainView = 
      <BrowserRouter>
       <Switch>
        <Route exact path="/" render={props => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }>   <Welcome db={firebase} /></Suspense>} />
        <Route exact path="/client" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Welcome /> </Suspense>} />
        <Route exact path="/admin" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/overview" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/feedback" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/add" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/history/:id" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/event/:id" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        />
      </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/add/feedback/:id" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /> </div> }> <Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/history" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /> </div> }> <Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/contactlist" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /> </div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/admin/scan" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><Admin db={firebase} /> </Suspense>} />
        <Route exact path="/add" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><Welcome db={firebase} /> </Suspense>} /> 
        <Route exact path="/add" render={() => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><Welcome db={firebase} /> </Suspense>} />
        <Route exact path="/event/:id"  render={({match}) => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><Event eventId={match} db={firebase} /> </Suspense>} />
        <Route exact path="/reg/:id" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><Reg eventId={match} db={firebase} /> </Suspense>} />
        <Route exact path="/feedback/:id" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#ef5002'}
          loading={true}
        /></div> }><SubmitFeedback eventId={match} db={firebase} /> </Suspense>} />  
        </Switch>
      </BrowserRouter>
   

    

    return (
      

     this.state.loading ? <div> {loadingView} </div> : <div>{mainView}</div>
      
    );
  }
}

export default App;
